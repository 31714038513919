import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const FavoritePhotographs = new Album(
  new Text('FavoritePhotographs.Title', 'Favorite Photographs'),
  Genre.Favorites,
  new Date('2089-03-17'),
  new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_022_large.webp', false, new Text('FavoritePhotographs.', 'Lake Baikal')),
  [
    new Photograph('/photographs/2023-11-18/2023-11-18_002.webp', false, new Text('FavoritePhotographs.', 'Ordesa and Monte Perdido National Park')),
    new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_001.webp', true, new Text('FavoritePhotographs.', 'Rådhuset Station')),
    new Photograph('/photographs/2022-07-08/2022-07-08_001.webp', true, new Text('FavoritePhotographs.', 'The Kiss of Death\n\nThe Kiss of Death is a marble sculpture, found in Poblenou Cemetery. The sculpture is thought to have been created by Jaume Barba, although others have claimed that its idea was conceived by Joan Fontbernat. The sculpture depicts death, in the form of a winged skeleton, planting a kiss on the forehead of a young man. The sculpture elicits varying responses from viewers concerning the depiction of the young man ranging from ecstasy to resignation.\n\n"His young heart is thus extinguished. The blood in his veins grows cold. And all strength has gone. Faith has been extolled by his fall into the arms of death. Amen."')),
    new Photograph('/photographs/2021-07-24_08-02/2021-07-24_08-02_018.webp', false, new Text('FavoritePhotographs.', 'Cape Meganom')),
    new Photograph('/photographs/2020-06-12/2020-06-12_001.webp', false, new Text('FavoritePhotographs.', 'Ekaterina Domracheva')),
    new Photograph('/photographs/2020-07-19_08-04_kate/2020-07-19_08-04_008.webp', false, new Text('FavoritePhotographs.', 'Ekaterina Domracheva')),
    new Photograph('/photographs/2020-07-19_08-04/2020-07-19_08-04_100.webp', false, new Text('FavoritePhotographs.', 'Great Aktru Glacier')),
    new Photograph('/photographs/2018-08-18/2018-08-18_005.webp', true, new Text('FavoritePhotographs.', 'Alina Cherdakova')),
    new Photograph('/photographs/2017-12-18_2018-01-05/2017-12-18_2018-01-05_022_large.webp', false,
      new Text('FavoritePhotographs.', 'St. Peter\'s Basilica\n\nThe Papal Basilica of Saint Peter in the Vatican, or simply Saint Peter\'s Basilica, is a church built in the Renaissance style located in Vatican City. It was initially planned by Pope Nicholas V and then Pope Julius II to replace the aging Old St. Peter\'s Basilica, which was built in the fourth century by Roman emperor Constantine the Great. Construction of the present basilica began on 18 April 1506 and was completed on 18 November 1626.\n\nDesigned principally by Donato Bramante, Michelangelo, Carlo Maderno and Gian Lorenzo Bernini, St. Peter\'s is the most renowned work of Renaissance architecture and the largest church in the world by interior measure. While it is neither the mother church of the Catholic Church nor the cathedral of the Diocese of Rome, St. Peter\'s is regarded as one of the holiest Catholic shrines. It has been described as "holding a unique position in the Christian world" and as "the greatest of all churches of Christendom."')),
    new Photograph('/photographs/2017-10-22/2017-10-22_016_large.webp', false, new Text('FavoritePhotographs.', 'Kira Orlova')),
    new Photograph('/photographs/2017-09-09_24/2017-09-09_24_030_large.webp', true,
    new Text('FavoritePhotographs.', 'Dumbo\n\nThe area known as Dumbo used to be known as Gairville. The area was originally a ferry landing, characterized by 19th- and early 20th-century industrial and warehouse buildings, Belgian block streets, and its location on the East River by the imposing anchorage of the Manhattan Bridge. The entirety of Dumbo was bought by developer David Walentas and his company Two Trees Management in the late 20th century, and remade into an upscale residential and commercial community—first becoming a haven for art galleries, and currently a center for technology startups.')),
    new Photograph('/photographs/2017-09-09_24/2017-09-09_24_001_large.webp', true,
      new Text('FavoritePhotographs.', 'Oculus / World Trade Center Station\n\nOne of the first decisions that Calatrava had in mind at the time of conceiving the project was the realization of the building at street level, an independent structure along the Wedge of Light Square, by Daniel Libeskind. “Oculus”, the centerpiece of the Transportation Center that presents the new station to the world is a kind of pause in the middle of the dense glass and steel towers that surround it.\n\nThe construction, due to constant delays, lasted 12 years and was finally inaugurated on March 3, 2016, without too many celebrations. The cost of its construction, $ 4 billion, greatly exceeded its original cost, becoming, until the time of its inauguration, the most expensive train station in the world and the third largest transportation center in New York, after Grand Central and Penn Station, both in Midtown Manhattan.')),
    new Photograph('/photographs/2017-01-30/2017-01-30_001.webp', false, new Text('FavoritePhotographs.', 'Pechersky Ascension Monastery\n\nPechersky Ascension Monastery is the principal monastery of the Nizhny Novgorod Eparchy and the seat of the Bishop of Nizhny Novgorod and Arzamas.\n\nIt have been founded ca. 1328-1330 by St. Dionysius, who came to Nizhny Novgorod from Kiev Pechersk Lavra with several other monks, and dug for himself a cave on the step Volga shore some 3 km southeast of the city. Later on, he founded at that site a monastery with a church of Resurrection of the Lord.\n\nThe monastery soon became an important spiritual and religious center of the Principality of Suzdal and Nizhny Novgorod.')),
    new Photograph('/photographs/2016-12-15/2016-12-15_001.webp', true, new Text('FavoritePhotographs.', 'Metro Bridge over the Oka River')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_041_large.webp', true, new Text('FavoritePhotographs.', '6th Avenue & West 42nd Street')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_009_large.webp', false,
      new Text('FavoritePhotographs.', 'Central Park\n\nBetween 1821 and 1855, New York City\'s population nearly quadrupled. As the city expanded northward up Manhattan Island, people were drawn to the few existing open spaces, mainly cemeteries, for passive recreation. These were seen as escapes from the noise and chaotic life in the city, which at the time was almost entirely centered on Lower Manhattan. The Commissioners\' Plan of 1811, the outline for Manhattan\'s modern street grid, included several smaller open spaces but not Central Park. As such, John Randel Jr. had surveyed the grounds for the construction of intersections within the modern-day park site.\n\nToday, Central Park is the most visited urban park in the United States and one of the most visited tourist attractions worldwide, with 42 million visitors in 2016.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_003_large.webp', true,
      new Text('FavoritePhotographs.', 'One World Trade Center\n\nOne World Trade Center is the main building of the rebuilt World Trade Center complex in Lower Manhattan. One WTC is the tallest building in the United States, the tallest building in the Western Hemisphere, and the seventh-tallest in the world. The supertall structure has the same name as the North Tower of the original World Trade Center, which was destroyed in the terrorist attacks of September 11, 2001. The new skyscraper stands on the northwest corner of the 6.5 ha World Trade Center site, on the site of the original 6 World Trade Center.')),
    new Photograph('/photographs/2016-04-27_05-04/2016-04-27_05-04_002.webp', false, new Text('FavoritePhotographs.', 'Mechanics of the Mercedes AMG Petronas team working on Nico Rosberg\'s W07 car.')),
    new Photograph('/photographs/2016-04-27_05-04/2016-04-27_05-04_004.webp', false, new Text('FavoritePhotographs.', 'Bolshoy Ice Dome\n\nThe Bolshoy Ice Dome is a multi-purpose indoor arena. Opened in 2012, the 12,000-seat arena was primarily constructed to host hockey competitions during the 2014 Winter Olympics. Following the Games, it became the home arena of HC Sochi, an expansion team of the KHL. The arena has also hosted concerts and other events. Prior to the Games, the arena hosted the IIHF World U18 Championships and Channel One Cup in 2013.')),
    new Photograph('/photographs/2015-08-12/2015-08-12_002.webp', false, new Text('FavoritePhotographs.', 'Meteor Rain')),
    new Photograph('/photographs/2016-04-16/2016-04-16_009.webp', false, new Text('FavoritePhotographs.', 'Ekaterina Malysheva')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_058_large.webp', false, new Text('FavoritePhotographs.', 'The Holy Nose Peninsula is on fire. Because of strong thunderstorms, since the end of July, violent fires have been raging in the mountains in the Baikal nature reserves. About 25,000 hectares of forest were affected by the fire.')),
    new Photograph('/photographs/2015-07-27_08-08/2015-07-27_08-08_022_large.webp', false, new Text('FavoritePhotographs.', 'Lake Baikal')),
    new Photograph('/photographs/2013-09-14_27/2013-09-27_108_large.webp', false, new Text('FavoritePhotographs.', 'Maidan Nezalezhnosti is the central square of Kyiv. One of the city\'s main squares. It has been known under many different names, but often it is called simply Maidan ("square"). In the 19th century, the square contained buildings of the city council and noble assembly. Since the start of Ukraine\'s independence movement in 1990, the square has been the traditional place for political rallies, including four large-scale radical protest campaigns: the 1990 student "Revolution on Granite", the 2001 "Ukraine without Kuchma", the 2004 Orange Revolution, and the 2013–14 Euromaidan. Maidan is also a regular site for non-political displays and events, however, since 2014 most of them were moved to Sofiyivska Square or elsewhere, because making entertainment in a place where people were killed during Euromaidan was considered inappropriate. Most notably, Christmas Fairs and New Year celebrations were moved to Sofiyivska Square.')),
    new Photograph('/photographs/2013-09-14_27/2013-09-17_015_large.webp', false, new Text('FavoritePhotographs.', 'The Vorontsov Lighthouse is a red-and-white, 27.2-meter tall lighthouse in the Black Sea port of Odessa. It is named after Prince Mikhail Semyonovich Vorontsov, one of the governors-general of the Odessa region. It has a one-million-watt signal light that can be seen up to twelve nautical miles (22 km) away. It transmits the Morse Code signal of three dashes, the letter O, for Odessa. It also sounds like a foghorn during severe storms or fog.')),
    new Photograph('/photographs/2013-01-01/2013-01-01_001.webp', true, new Text('FavoritePhotographs.', 'A frosty morning near Volga embankment. The early hours of the new year.')),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_037_large.webp', true, new Text('FavoritePhotographs.', 'The Sheikh Zayed Grand Mosque is located in Abu Dhabi, the capital city of the United Arab Emirates. The largest mosque in the country, it is the key place of worship for daily prayers. During Eid, it was visited by more than 41,000 people.')),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_024_large.webp', false, new Text('FavoritePhotographs.', 'The Dubai Metro is a rapid transit rail network in the city of Dubai, United Arab Emirates.  It is currently operated by the British company Serco. Until 2016, the Dubai Metro was the world\'s longest driverless metro network with a route length of 75 kilometres. Planning of the Dubai Metro began under the directive of Dubai\'s Ruler, Sheikh Mohammed bin Rashid Al Maktoum, who expected other projects to attract 15 million visitors to Dubai by 2010.')),
    new Photograph('/photographs/2012-11-09_21/2012-11-09_21_059_large.webp', false, new Text('FavoritePhotographs.', 'Dubai Mall')),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_016_large.webp', false, new Text('FavoritePhotographs.', 'The Kazan Kremlin is the chief historic citadel of Russia, situated in the city of Kazan. It was built at the behest of Ivan the Terrible on the ruins of the former castle of Kazan khans. It was declared a World Heritage Site in 2000.')),
  ]);

export default FavoritePhotographs;